
h1 {
  padding-top: 10px;
}

blockquote {
  background: #00e1ff;
  border-left: 10px solid #ff00b2;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}

blockquote p {
  display: inline;
}

.topcontainer {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.container {

    margin-right: auto;
    margin-left: auto;
    padding-right: 10px;
    padding-left: 10px;
    /* for edge cases: */
    width: 100%;
    max-width: 100%;

  }

  .content {
    flex: 1;
  }
  
  .wximage {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .wxbadge {
    padding-top: 4px;
    padding-right: 10px;
    float: left;
  }

  .wxclear {
    clear: both;
  }

  #wxdivider {
    margin-top: 10px;
  }

  .wxhrfooter {
    border-top: 1px solid white;
  }

  .figcaption {
    border: none;
    text-align: center;
  }

  @media (min-width: 768px) {
    .container{
      width: 750px;
    }
    .wxbadge {
      padding-bottom: 4px;
    }
  }
  
  @media (min-width: 992px) {
    .container{
      width: 970px;
    }
    .wxbadge {
      padding-bottom: 10px;
    }
  }
  
  @media (min-width: 1200px) {
    .container{
      width: 1170px;
    }
    .wxbadge {
      padding-bottom: 10px;
    }
  }

  /*   Sticky Footer */
  .footer {
    margin-top: auto;
  }
 

  