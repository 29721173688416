/* Ref: https://stackoverflow.com/questions/5438567/css-submit-button-weird-rendering-on-ipad-iphone */

.Header {
    display: grid;
    grid-template-areas: "logo nav";
    background: #0000ff;
    margin-top: 1px;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.Nav {
    grid-area: nav;
    display: grid;
    grid-template-columns: repeat(7, auto);
    align-items: center;
    justify-items: center;
}

.link-white {
    color: white;
}

.link-yellow {
    color: yellow;
}

.Burger {
    display: none;
    grid-area: burger;
    justify-self: end;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}

.Burger :active {
    transform: scale(1.2);
}

@media (max-width: 700px) {
    .Header {
        grid-template-areas: "logo burger" "nav nav";
    }

    .Nav {
        grid-template-rows: repeat(4, auto);
        grid-template-columns: none;
        row-gap: 20px;
    }

    .Burger {
        display: block;
    }
}